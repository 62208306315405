import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "../../components/Layout/Layout.js";
import useContentful from "../../utils/useContentful";
import useContentful2 from "../../utils/useContentful2";
import BoldContentful from "../../utils/BoldContentful";
import "../../styles/global.css";
import Hero from "../../components/comotrabajamos/Hero.js";
import Titlerich from "../../components/Titlerich.js";
import Button from "../../components/Boton.js";
import MetaTags from "react-meta-tags";
import Bottomsection from "../../components/proyectos/pais/becatalento/Bottomsection.js";
import { Helmet } from "react-helmet";
import Home from "../../images/Home/home.png";
import { Link } from "gatsby";

const Container = styled.div`
  position: relative;
  width: 100%;
  /*max-width: 1440px;*/
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 78px;
`;

const Pstyled = styled.p`
  color: var(--gris);
  width: 65%;
  padding: 20px 30px;
  text-align: start;
  line-height: 22px;

  p {
    margin-bottom: 16px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

///////////////////////////////

const Containercards = styled.div`
  width: 100%;
  //height:726px;
  padding-bottom: 80px;
  background-color: var(--blanco);
  display: flex;
  //justify-content: center;
  align-items: center;
  padding-top: 40px;
  flex-direction: column;
  position: relative;

  @media (max-width: 900px) {
    height: auto;
    padding-top: 0px;
    padding-bottom: 16px;
  }
`;

const Subcontainercards = styled.div`
  width: 90%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  z-index: 1;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Containercard = styled.div`
  position: relative;
  width: 26%;
  display: flex;
  height: 448px;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background-image: url(${(p) => p.img});
  background-size: cover;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px;

  :hover {
    //display:none ;
    box-shadow: 0px 0px 40px rgba(${(p) => p.shad}, 0.4);
    justify-content: center;
    padding: 0px;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding-bottom: 16px;
  }
`;

const Cards = styled.div`
  width: 100%;
  padding: 0px 0px;
  display: flex;
  justify-content: center;
  gap: 38px;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0px 30px;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const Titulo = styled.div`
  height: 10%;
  font-weight: 800;
  z-index: 2;
  color: var(--blanco);
  ${Containercard}:hover & {
    color: var(--blanco);
  }
`;

const Content = styled.div`
  height: 60%;
  width: 100%;
  color: var(--blanco);
  text-align: center;
  padding: 0 10%;
  display: none;
  z-index: 2;

  ${Containercard}:hover & {
    display: flex;
  }

  @media (max-width: 900px) {
    height: 66%;
  }

  @media (max-width: 900px) and (min-width: 1200px) {
    height: 70%;
  }
`;

const Covercolor = styled.div`
  position: absolute;
  background-color: rgba(${(p) => p.color}, 0.95);
  width: 100%;
  height: 100%;
  display: none;
  bottom: 0px;
  z-index: 1;
  ${Containercard}:hover & {
    display: flex;
  }
`;

const Coverblack = styled.div`
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  width: 100%;
  height: 100%;
  display: flex;
  bottom: 0px;
  z-index: 1;
  ${Containercard}:hover & {
    display: none;
  }
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 32px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h {
    width: 80%;
    font-weight: 700;
    font-size: 24px;
    color: var(--magenta);
    margin-bottom: 16px;
    text-align: center;
  }
  p {
    font-size: 20px;
    text-align: center;
    strong {
      font-weight: 800;
    }
  }

  @media (max-width: 900px) {
    h {
      width: 90%;
    }
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-end;
  svg {
    margin: 12px;
    cursor: pointer;
  }
`;

const Modal = styled.div`
  position: fixed;
  width: 50vw;
  height: 70vh;
  left: 50vw;
  top: 50vh;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  z-index: 100;
  border-radius: 24px;
  box-shadow: 0px 0px 40px rgba(179, 179, 179, 0.4);
  flex-direction: column;
  display: ${(p) => (p.active ? "flex" : "none")};

  @media (max-width: 900px) {
    width: 94vw;
    height: 96vh;
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  flex-direction: row
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  width: 90%;
`;

const Homeimg = styled.img`
  width: 32px;
`;

//////////////////////////////////

const Card = (props) => {
  return (
    <Containercard img={props.image} shad={props.shadow}>
      <Covercolor color={props.color}></Covercolor>
      <Coverblack></Coverblack>
      <Titulo clr={props.color}>{props.title}</Titulo>
      <Content>{props.text}</Content>
      <Button enlace={props.enlace}>Conoce más</Button>
    </Containercard>
  );
};

const Cardsproyecto = ({
  card1img,
  card1titulo,
  card1texto,
  card2img,
  card2titulo,
  card2texto,
  card3img,
  card3titulo,
  card3texto,
}) => {
  return (
    <Containercards>
      <Subcontainercards>
        <Cards>
          <Card
            color="var(--moradorgb)"
            shadow="var(--moradorgb)"
            image={card1img}
            text={card1texto}
            title={card1titulo}
            enlace={"/proyectos/pais"}
          ></Card>

          <Card
            color="var(--verdergb)"
            shadow="var(--verdergb)"
            image={card2img}
            text={card2texto}
            title={card2titulo}
            enlace={"/proyectos/breca"}
          ></Card>

          <Card
            color="var(--magentargb)"
            shadow="var(--magentargb)"
            image={card3img}
            text={card3texto}
            title={card3titulo}
            enlace={"/proyectos/breit"}
          ></Card>
        </Cards>
      </Subcontainercards>
    </Containercards>
  );
};

const HomeEntryId = "2oDMDgz8LGunEWkUp6HTSA";
const ProyectosEntry = "3Mfj3eAWzZD0hnXjw8JEXd";
const GeneralEntryId = "8zG9dMMm9VFjkRLfm9y3W";
const IndexPage = ({ location }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { data: home, fetched3 } = useContentful({ id: HomeEntryId });
  const { data: proyectos, fetched } = useContentful2({ id: ProyectosEntry });
  const { data: general, fetched: fetched2 } = useContentful({
    id: GeneralEntryId,
  });

  const handleCancel = () => {
    setIsModalOpen(false);
  };
 
  return (
    <>
      {fetched && fetched2 ? (
        <>
          <MetaTags>
            <title>{proyectos.fields.pageTitleProyectos}</title>
            <meta
              name={proyectos.fields.pageTitleProyectos}
              content={proyectos.fields.pageTitleProyectos}
            />
            <meta name="robots" content="index,follow" />
            <link
              rel="alternate"
              hrefLang="x-default"
              href="https://www.aporta.org.pe/proyectos/"
            />
            <link
              rel="alternate"
              hrefLang="es-PE"
              href="https://www.aporta.org.pe/proyectos/"
            />
            <link rel="canonical" href="https://www.aporta.org.pe/proyectos/" />
          </MetaTags>
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Aporta",
                    item: "https://www.aporta.org.pe/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: "Proyectos",
                    item: "https://www.aporta.org.pe/proyectos/",
                  },
                ],
              })}
            </script>
          </Helmet>
          <Layout
            margen={false}
            direccion={general.fields.direccion}
            facebook={general.fields.facebook}
            instagram={general.fields.instagram}
            linkedin={general.fields.linkedin}
            email={general.fields.email}
            telefono={general.fields.telefono}
            youtube={general.fields.youtube}
          >
            <Container>
              {home.fields.modalActivation ? (
                <>
                  <Modal active={isModalOpen}>
                    <Header>
                      <svg
                        onClick={() => handleCancel()}
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_512_3797)">
                          <path
                            d="M19.4109 18.0002L27.701 9.71019C27.8648 9.51888 27.9504 9.27281 27.9407 9.02113C27.9309 8.76946 27.8266 8.53072 27.6485 8.35262C27.4704 8.17453 27.2317 8.0702 26.98 8.06047C26.7283 8.05075 26.4823 8.13636 26.291 8.30019L18.0009 16.5902L9.71095 8.29019C9.52265 8.10188 9.26725 7.99609 9.00095 7.99609C8.73465 7.99609 8.47925 8.10188 8.29095 8.29019C8.10265 8.47849 7.99686 8.73388 7.99686 9.00019C7.99686 9.26649 8.10265 9.52188 8.29095 9.71019L16.591 18.0002L8.29095 26.2902C8.18627 26.3798 8.10125 26.4902 8.04122 26.6142C7.9812 26.7383 7.94747 26.8734 7.94215 27.0111C7.93683 27.1489 7.96004 27.2862 8.01031 27.4145C8.06059 27.5428 8.13684 27.6594 8.2343 27.7568C8.33175 27.8543 8.4483 27.9305 8.57663 27.9808C8.70495 28.0311 8.84228 28.0543 8.98 28.049C9.11772 28.0437 9.25285 28.0099 9.37692 27.9499C9.50098 27.8899 9.6113 27.8049 9.70095 27.7002L18.0009 19.4102L26.291 27.7002C26.4823 27.864 26.7283 27.9496 26.98 27.9399C27.2317 27.9302 27.4704 27.8258 27.6485 27.6477C27.8266 27.4697 27.9309 27.2309 27.9407 26.9792C27.9504 26.7276 27.8648 26.4815 27.701 26.2902L19.4109 18.0002Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_512_3797">
                            <rect width="36" height="36" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Header>
                    <Body>
                      <h>{home.fields.popUp.fields.modalTitle}</h>

                      <p>
                        {BoldContentful(
                          home.fields.popUp.fields.modalText,
                          "var(--black)"
                        )}
                      </p>
                      <Bottomsection
                        clr={"var(--celeste)"}
                        titulo={home.fields.popUp.fields.buttonsTitle}
                        button={home.fields.popUp.fields.buttonslinks.button}
                        bottomtext={
                          home.fields.popUp.fields.buttonslinks.bottomtext
                        }
                      ></Bottomsection>
                    </Body>
                  </Modal>
                </>
              ) : (
                <></>
              )}

              <Hero
                imagen={proyectos.fields.headerImagenDeFondo.fields.file.url}
              ></Hero>
              <Breadcrumbs>
                <Link
                  to="/"
                  className={
                    location.pathname.startsWith("/proyectos")
                      ? "breadcrumb-active"
                      : "breadcrumb-not-active"
                  }
                  style={{ textDecoration: "none" }}
                >
                  <Homeimg src={Home} alt="homeimage" />
                </Link>
                <span
                  className="breadcrumb-arrow"
                  style={{ color: "var(--celeste)" }}
                >
                  &gt;
                </span>
                <Link
                  to="/proyectos"
                  className={"breadcrumb-not-active"}
                  style={{ textDecoration: "none" }}
                >
                  Proyectos
                </Link>
              </Breadcrumbs>
              <Titlerich
                colortext={"var(--negro)"}
                container={proyectos.fields.tituloRich}
                color={"var(--celeste"}
                colorbold={"var(--negro"}
              ></Titlerich>
              <Pstyled>
                {BoldContentful(
                  proyectos.fields.descripcionRich,
                  "var(--negro)"
                )}
              </Pstyled>
              <Cardsproyecto
                card1img={
                  proyectos.fields.innovacionSocialPaisImagen.fields.file.url
                }
                card1titulo={proyectos.fields.innovacionSocialPaisTitulo}
                card1texto={proyectos.fields.innovacionSocialPais}
                card2img={
                  proyectos.fields.innovacionSocialBrecaImagen.fields.file.url
                }
                card2titulo={proyectos.fields.innovacinSocialBrecaTitulo}
                card2texto={proyectos.fields.innovacinSocialBreca}
                card3img={proyectos.fields.breitImagen.fields.file.url}
                card3titulo={proyectos.fields.breitTitulo}
                card3texto={proyectos.fields.breit}
              ></Cardsproyecto>
            </Container>
          </Layout>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            padding: "10%",
          }}
        >
          {" "}
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBlackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="var(--celeste)"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        </div>
      )}
    </>
  );
};

export default IndexPage;
