import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  background-image: url(${(p) => p.image});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  margin-bottom: 40px;

  @media (max-width: 900px) {
    height: 230px;
    margin-bottom: 40px;
  }
`;

const Hero = (props) => {
  return <Container image={props.imagen}></Container>;
};

export default Hero;
